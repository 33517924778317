.custom-footer-container {
  width: 100%;
  height: 300px;
  border-top: solid black 2px;
}

.custom-footer-addy {
  justify-content: center;
  margin-top: 10px;
}

.custom-footer-hours {
  text-decoration: underline;
}

.custom-hover-social-img {
  transition: 1s;
  border-radius: 15px;

}

.custom-hover-social-img:hover {
  height: 60px;
  width: 60px;
  box-shadow: green 0px 0px 2px 2px;
  background-color: #000000;
}

.custom-footer-number:hover {
  color: rgb(60, 255, 0);
}

.custom-footer-number {
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.custom-footer-link {
  transition: 1s;
}

.custom-footer-link:hover {
  padding-left: 20px;
  color: rgb(0, 0, 0);
}

.custom-phone-icon {
  margin-top: 4px;
}

.footer-num-2 {
  padding-top: 3px;
  padding-bottom: 3px;
  padding-right: 3px;
  border: solid rgba(255, 255, 255, 0) 1px;
  border-radius: 10px;
  transition: .5s;
}

.footer-num-2:hover {
  background-color: #224dac;
  border: solid black 1px;
  box-shadow: black 1px 1px 4px 1px;
}

.footer-logo {
  box-shadow: black 0px 0px 5px 2px;
}

.footer-logo:hover {
  box-shadow: #224dac 0px 0px 5px 2px;
}

@media screen and (min-width: 416px) and (max-width: 1075px) {
  .footer-logo {
    position: relative;
    object-position: 50%;
    display: flex;
    justify-content: center;
    margin-top: -175px;
    height: 75px;
    width: 75px;
  }

  .custom-footer-container {
    height: 340px;
  }
}

@media screen and (min-width: 850px) {
  .footer-logo {
    position: relative;
    object-position: 50%;
    display: flex;
    justify-content: center;
    margin-top: -175px;
  }
}

@media only screen and (min-width: 415px) and (max-width: 767px) {
  .custom-footer-container {
    width: 100%;
    /* height: 670px; */
    height: 100%
  }

  .footer-logo {
    position: relative;
    object-position: 50%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 415px) {
  .custom-footer-container {
    width: 100%;
    height: 100%
  }
}

@media (max-width: 400px) {
  .custom-footer-number {
    display: none;
  }
}

@media (min-width: 400px) {
  .custom-footer-number-2 {
    display: none;
  }
}

.custom-footer-number-2 {
  font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
  margin-right: -10px;
}

.custom-footer-number-2:hover {
  color: rgb(60, 255, 0);
}
