.reviews-api-container {
  background-color: #f8f8f8;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  margin-bottom: 5px;
  margin-left: 5px;
  box-shadow: black 2px 2px 4px 2px;
}


.review-api {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: black 0px 0px 4px 2px;
}


.review-api p {
  margin: 0;
}


.review-rating-api {
  margin-top: 10px;
  color: #ff9800;
}


.review-author-api {
  margin-top: 5px;
  font-style: italic;
}


.star-filled-api {
  color: #ff9800;
}

.star-empty-api {
  color: #ccc;
}

/* @media screen and (min-width: 1284px) {
  .custom-large-overflow {
    overflow-y: scroll;
  }
} */

/* @media screen and (min-width: 724px) {
  .custom-large-overflow {
    overflow-y: scroll;
    min-height:325px;
    max-height: 685px;
    min-width: 300px;
  }
} */

@media screen and (min-width: 1284px) {
  .custom-api-review-size {
    max-height: min-content;
  }
}

.view-all-reviews-button {
  background-color: #b2cbff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: black 0px 0px 4px 2px;
  transition: .5s;
}

.view-all-reviews-button:hover {
  background-color: #224dac;
  color: white;
  padding: 10px;
  border-radius: 10px;
  box-shadow: white 0px 0px 4px 2px;
}

.custom-api-review-size {
  background-color: #224dac;
}
