.mens-health-img {
  width: 30vw;
  height: 25vw;
  margin-left: 30px;
  margin-bottom: 10px;
  border-radius: 10px;
  box-shadow: grey 1px 1px 4px 2px;
  float: right;
}


.mens-health-mobile-style {
  border-radius: 10px;
  box-shadow: grey 1px 1px 4px 2px;
  margin-bottom: 10px;
}


