.custom-doc-img {
  border-radius: 30px;
  box-shadow: black 1px 1px 4px 2px;
}

.doctor-about-text {
  box-shadow: black 1px 1px 3px 1px;
  background-color: #f8f8f8c9;
  font-weight: bold;
  color: rgb(0, 0, 0);
}

.about-img-container {
  display: flex;
  justify-content: center;
  border-radius: 30px;
}


.about-bg-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: -1;
}

.about-background {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  opacity: 75%;
}

.profile-card-container {
  background-color: rgba(240, 248, 255, 0.842);
  border-radius: 30px;
  margin-top: 10px;
  box-shadow: rgb(0, 0, 0) 1px 1px 4px 1px;
}

@media only screen and (max-width: 767px) {
  .profile-card-container {
    background-color: rgba(240, 248, 255, 0);
    border-radius: 30px;
    margin-top: 10px;
  }

  .about-text-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}


.play-button {
  display: none; /* Hidden by default */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 10px 20px;
  font-size: 18px;
  background-color: rgba(255, 255, 255, 0.7);
  border: none;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1;
}
