.custom-homepage-video-container {
  width: 100%;
  max-height: 550px;
  object-fit: cover;
  object-position: 50% 37%;
  border-bottom: solid black 1px;
}

.google-map-homepage {
  border-top: solid black 2px;
}

.home-welcome-text {
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.animated-word {
  display: inline-block;
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
}

.animated-word.fade-out {
  opacity: 0;
}

.home-welcome-message-container {
  background-color: rgba(255, 255, 255, 0.637);
  border-radius: 10px;
  color: rgb(0, 0, 0);
  box-shadow: rgba(0, 0, 0, 0.596) 1px 1px 3px 2px;
}

.home-text-outline {
  text-shadow: 2px 2px 2px rgb(255, 255, 255);
}

@media (min-width: 431px) {
  .home-page-text {
    padding: 40px;
    border: solid black 3px;
    border-radius: 10px;
    box-shadow: black 0px 0px 10px 3px;
    background-color: rgb(240, 248, 255);
  }
}

@media (max-width: 431px) {
  .home-page-text {
    padding: 7px;
    border: solid black 3px;
    border-radius: 10px;
    box-shadow: black 0px 0px 4px 1px;
    background-color: rgb(240, 248, 255);
  }
}

.home-page-info-container {
  /* position: relative;
  width: 100%;
  overflow: hidden; */
  background-image: url('./desert-about-bg.jpeg');
  background-size: cover;
}

/* Ensure the video covers the entire container */
#bg-video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Cover the entire container without maintaining aspect ratio */
  transform: translate(-50%, -50%);
  z-index: -1; /* Ensure the video is behind other content */
}
