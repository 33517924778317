.service-background-gradient {
  background: linear-gradient(0deg, rgb(34, 78, 172, 0.788) 0%, rgba(250,250,250,1) 33%, rgba(255,255,255,1) 66%, rgba(34, 78, 172, 0.788) 100%);
}

.services-page-container {
  background-image: url('./images/servicesBg.jpeg');
  background-size: cover;
  border-bottom: black solid 2px;
}

.services-grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  /* Adjust the minmax value as needed */
  row-gap: 15px;
  column-gap: 15px;
  /* Adjust the gap between squares */
  max-width: 1000px;
  /* Adjust the maximum width of the grid */
  margin: auto;
  /* Center the grid */
  padding-left: 10px;
  padding-right: 10px;
}

@media screen and (min-width: 431px) {
  .service-links {
    /* Adjust the padding */
    text-align: center;
    /* Center the content */
    text-decoration: none;
    /* Remove default underline */
    height: 150px;
    width: auto;
  }


  .services-link-title,
  .service-links {
    display: block;
    width: 100%;
    padding: 0;
    box-sizing: border-box; /* Add this line */
  }

  .services-link-title {
    background-color: #224eac;
    border-bottom: black solid 1px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: white;
    text-decoration: underline;
    box-shadow: black 0px 0px 4px 1px;
    margin-top: -29px;
    padding-top: 3px;
    padding-bottom: 1px;
  }


  .service-links {
    box-shadow: black 0px 0px 4px 1px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    margin: 0;
  }

  .service-link-wraper {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: solid black 1px;
    padding-top: 29px;
  }

  .service-link-wraper:hover {
    box-shadow: #224dac 0px 0px 5px 5px;
  }
  /* service link background ids */

#service-primary-care {
  background-image: url('./images/primary-health.jpeg');
  background-size: cover;
}

#service-womens-health {
  background-image: url('./images/female.jpeg');
  background-size: cover;
}

#service-mens-health {
  background-image: url('./images/male.jpeg');
  background-size: cover;
  background-size: 110%;
  background-position: center;
}

#service-std-screenings {
  background-image: url('./images/blood-sample.jpeg');
  background-size: cover;
}

#service-walk-in {
  background-image: url('./images/walk-ins.jpeg');
  background-size: cover;
}

#service-telehealth {
  background-image: url('./images/telehealth.jpeg');
  background-size: cover;
}

#service-aesthetics {
  background-image: url('./images/botox.jpeg');
  background-size: cover;
}

#service-sick-visits {
  background-image: url('./images/sick.jpeg');
  background-size: cover;
}

#service-fpact {
  background-image: url('./images/vaginal-health.jpeg');
  background-size: cover;
}

#service-every-woman-counts {
  background-image: url('./images/beast-cancer.jpeg');
  background-size: cover;
}

#service-cash-patients {
  background-image: url('./images/no-problem.jpeg');
  background-size: cover;
}

#service-lipo-c {
  background-image: url('./images/weight-loss.jpeg');
  background-size: cover;
}

#male-erectile-dysfunction {
  background-image: url('./images/male-ed.jpeg');
  background-size: cover;
}

#service-birth-control {
  background-image: url('./images/birth-control.jpeg');
  background-size: cover;
}

#service-pregnancy {
  background-image: url('./images/pregnancy-test.jpeg');
  background-size: cover;
  background-size: 200%;
  background-position: center;
}

#service-immigration-physical {
  background-image: url('./images/immigration-physical.jpeg');
  background-size: cover;
}

}

@media screen and (max-width: 431px) {
 .service-links {
   padding: 15px;
   text-align: center;
   text-decoration: none;
   background-color: #224dac;
   color: white;
   font-size: 20px;
   width: 100%;
   border-radius: 10px;
   border: solid black 1px;
   box-shadow: black 0px 0px 5px 0px;
 }


}
