body {
  font-family: "Sedan", serif;
  font-weight: 400;
  font-style: normal;
}



@tailwind base;
@tailwind components;
@tailwind utilities;
