.custom-nav-css {
  transition: .5s;
  padding: 7px;
  margin-left: 1px;
  margin-right: 1px;
  border-radius: 10px;
  border: solid rgba(255, 255, 255, 0) 1px;
}

.custom-nav-css:hover {
  background-color: #224dac;
  border-radius: 10px;
  border: black solid 1px;
  color: rgb(255, 255, 255);
}


.custom-menu-slide {
  position: absolute;
  left: -100%; /* Start off-screen to the left */
  width: 100%;
  background-color: #0e378f; /* Change this to your desired background color */
  color: #fff; /* Change this to your desired text color */
  padding: 20px;
  animation: slideIn 1s forwards; /* Animation definition */
  z-index: 20;
}

@keyframes slideIn {
  to {
      left: 0; /* Slide to the left edge of the screen */
  }
}

@keyframes slideOut {
  to {
      left: -100%; /* Slide to the left edge of the screen */
  }
}

.custom-new-patient {
  background-color: #224dac;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 30px;
  border-radius: 10px;
  border: black solid 2px;
  color: #fff;
  transition: .5s;
}

.custom-new-patient:hover {
  box-shadow: black 1px 1px 4px 1px ;
  color: rgb(60, 255, 0);
}

.custom-header-directions {
  border: rgb(255, 255, 255) solid 1px;
  transition: .5s;
  border-radius: 10px;
}

.custom-header-directions:hover {
  background-color: #224dac;
  border-radius: 10px;
  border: solid black 1px;
  box-shadow: black 1px 1px 4px 1px;
}

.custom-header-directions-text {
  transition: .5s;
}

.custom-header-directions-text:hover {
  font-weight: 100;
  color: rgb(60, 255, 0);
}

.custom-header-title {
  margin-top: 10px;
}

.active {
  border-bottom: #224dac solid 3px;
}

@media (max-width: 768px) {
.phone-menu-button {
  width: 100px;
  height: 56px;
  display: flex;
  justify-content: center;
}
}
